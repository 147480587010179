import 'https://storage.googleapis.com/courier-web-component/fullscreen-courier-chat.umd.js?etag=1112';
import { FullscreenCourierChatElement } from '../courier-web-components';
import React from 'react';
import config from '../config';

type CourierContextType = {
  openChat: VoidFunction;
  closeChat: VoidFunction;
  hasUnreadMessages: boolean;
};

const CourierContext = React.createContext<CourierContextType | undefined>(
  undefined
);

type Props = React.PropsWithChildren;

export const CourierProvider: React.FC<Props> = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [hasUnreadMessages, setHasUnreadMessages] = React.useState(false);
  const chatRef = React.useRef<FullscreenCourierChatElement>(null);

  React.useEffect(() => {
    if (chatRef.current) {
      chatRef.current.isOpen = isOpen;
      chatRef.current.app = config.APP_ID;
    }
  }, [isOpen]);

  React.useEffect(() => {
    const ref = chatRef.current;

    if (ref) {
      ref.addEventListener('onClose', () => {
        setIsOpen(false);
      });

      ref.addEventListener('getUnreadCount', (e: any) => {
        setHasUnreadMessages(!!e.detail);
      });

      return () => {
        ref.removeEventListener('onClose', () => {
          setIsOpen(false);
        });
      };
    }
  }, []);

  const openChat = () => {
    setIsOpen(true);
  };

  const closeChat = () => {
    setIsOpen(false);
  };

  return (
    <CourierContext.Provider
      value={{
        openChat,
        closeChat,
        hasUnreadMessages,
      }}
    >
      <fullscreen-courier-chat ref={chatRef} />
      {children}
    </CourierContext.Provider>
  );
};

export default CourierContext;

export const useCourier = (): CourierContextType => {
  const context = React.useContext(CourierContext);
  if (!context) {
    throw new Error('useCourier must be used within a CourierContext');
  }
  return context;
};
