const config = {
  APP_ID: 'my.sportnet.online',
  CORE_API_URL:
    process.env.REACT_APP_CORE_API_BASE_URL || 'https://api.sportnet.online/v1',
  cookies: {
    accessToken: 'access_token',
    guardianAccessToken: 'guardian_access_token',
  },
  lists: {
    invitations: {
      limit: 20,
    },
    messages: {
      limit: 20,
    },
  },
};

export default config;
