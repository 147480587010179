import { ApiError } from './library/CoreApi';
import { QueryClient } from 'react-query';

export const makeClient = (opts: any = {}) =>
  new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 5 * 60 * 1000, // 5 min,
        cacheTime: 5 * 60 * 1000, // 5 min,
        retry: false,
        ...opts,
        onError(err: ApiError) {
          console.error(err);
        },
      },
    },
  });
