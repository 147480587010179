import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';
import { createRoot } from 'react-dom/client';
import { theme } from './theme/theme';
import App from './App';
import Cookies from 'js-cookie';

const root = createRoot(document.getElementById('root') as HTMLElement);

Cookies.remove('access_token');

root.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ThemeProvider>
);
